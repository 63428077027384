<template>
  <nav
    class="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg"
  >
    <div
      class="container px-4 mx-auto flex flex-wrap items-center justify-between"
    >
      <a
        href="mailto:info@wakefield-studios.com"
        class="text-sm text-white"
        >info@wakefield-studios.com</a
      >
      <ul class="flex lg:flex-col flex-row list-none lg:ml-auto">
        <li class="flex items-center">
          <a
            class="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
            href="https://www.facebook.com/wakefieldgamestudio"
            target="_blank"
          >
            <i class="text-blueGray-200 fab fa-facebook text-lg leading-lg" />
          </a>
        </li>

        <li class="flex items-center">
          <a
            class="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
            href="https://x.com/WakefieldStudio"
            target="_blank"
          >
            <i class="text-blueGray-200 fab fa-twitter text-lg leading-lg" />
          </a>
        </li>

        <li class="flex items-center">
          <a
            class="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
            href="https://github.com/wakefield-studios"
            target="_blank"
          >
            <i class="text-blueGray-200 fab fa-github text-lg leading-lg" />
          </a>
        </li>

        <!-- <li class="flex items-center">
            <button
              class="bg-white text-blueGray-700 active:bg-blueGray-50 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
              type="button"
            >
              <i class="fas fa-arrow-alt-circle-down"></i> Download
            </button>
          </li> -->
      </ul>
    </div>
  </nav>
</template>
<script>
// import PagesDropdown from '@/components/Dropdowns/PagesDropdown.vue';
export default {
  data() {
    return {
      navbarOpen: false,
    };
  },
  methods: {
    setNavbarOpen: function () {
      this.navbarOpen = !this.navbarOpen;
    },
  },
  components: {
    // PagesDropdown,
  },
};
</script>
