<template>
  <div>
    <navbar />
    <main>
      <div
        class="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75"
      >
        <div
          class="absolute top-0 w-full h-full bg-cover"
          style="background-image: url('assets/img/wfs_landing_bg_640x480.jpg')"
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute bg-gray opacity-50"
          ></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <div>
                <img :src="wfs_logo" />
                <p class="text-base text-blueGray-200">Illuminating Games</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="pb-20 bg-blueGray-800 -mt-24">
        <div class="container mx-auto px-4">
          <div class="w-full flex justify-center">
            <div class="px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-black text-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <h6
                    class="text-xl font-semibold mb-4 underline text-yellow-300"
                    style="font-family: Courier New; color: yellow"
                  >
                    <a
                      href="https://dungeonteam.com"
                      target="_blank"
                      ><span class="mr-2">Play Dungeon Team</span
                      ><i class="fas fa-external-link"></i>
                    </a>
                  </h6>
                  <iframe
                    width="560"
                    height="315"
                    class="max-w-full"
                    src="https://www.youtube.com/embed/wW5NpoZPy6U?si=crRfNfesAmG6fgfj"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="pt-20 pb-20">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center">
            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 px-4">
              <div class="px-6">
                <img
                  alt="..."
                  :src="lombardo_portrait"
                  class="shadow-lg rounded-full mx-auto max-w-120-px"
                />
                <div class="pt-2 text-center">
                  <h5 class="text-xl font-bold">Robert Lombardo</h5>
                  <p
                    class="mt-1 text-sm text-blueGray-400 uppercase font-semibold"
                  >
                    Lead Developer
                  </p>
                  <div class="mt-2">
                    <button
                      class="bg-lightBlue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <a
                        href="https://www.linkedin.com/in/robert-lombardo-6b33042a/"
                        target="_blank"
                      >
                        <i class="fab fa-linkedin"></i
                      ></a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from '@/components/Navbars/AuthNavbar.vue';
import FooterComponent from '@/components/Footers/Footer.vue';

// import wfs_bg from '@/assets/img/wfs_landing_bg_640x480.jpg';
import wfs_logo from '@/assets/img/wfs_logo.png';
import lombardo_portrait from '@/assets/img/lombardo_portrait.jpeg';

export default {
  data() {
    return {
      wfs_logo,
      lombardo_portrait,
    };
  },
  components: {
    Navbar,
    FooterComponent,
  },
};
</script>
