<template>
  <div>
    <div class="static mt-4 w-full flex justify-center text-xs underline">
      <router-link to="/">home</router-link>
    </div>
    <iframe
      class="w-full h-screen"
      src="https://docs.google.com/document/d/e/2PACX-1vTDj_bkWJg1XAv8yCq1TH00Atoowc13rKmR0iqlY2laESl7YsXgrzvfPSG1YRQqgz-iWkRrAYyZInr7/pub?embedded=true"
    ></iframe>
  </div>
</template>
